.of-contain {
    object-fit: contain;
}

.of-cover {
    object-fit: cover;
}

.of-fill {
    object-fit: fill;
}

.of-scale-down {
    object-fit: scale-down;
}

.of-none {
    object-fit: none;
}

.op-center {
    object-position: center;
}

.cursor-pointer {
    cursor: pointer;
}