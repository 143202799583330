@for $i from 0 to 100 {
    .br-#{$i}px {
        border-radius: #{$i}px !important;
    }
}

@for $i from 0 to 100 {
    .br-t-#{$i}px {
        border-radius: #{$i}px #{$i}px 0px 0px !important;
    }
}

@for $i from 0 to 100 {
    .br-e-#{$i}px {
        border-radius: 0px #{$i}px #{$i}px 0px !important;
    }
}

@for $i from 0 to 100 {
    .br-b-#{$i}px {
        border-radius: 0px 0px #{$i}px #{$i}px !important;
    }
}

@for $i from 0 to 100 {
    .bs-b-#{$i}px {
        border-radius: #{$i}px 0px 0px #{$i}px !important;
    }
}