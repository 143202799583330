.pq-toast-container {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 200px;
    height: auto;
    z-index: 1001;

    .slide-in {
        animation-name: fade-in;
        animation-fill-mode: forwards;
        animation-duration: 0.1s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
    }

    .slide-out {
        animation-name: fade-out;
        animation-fill-mode: forwards;
        animation-duration: 0.1s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}