@import '../variables/index.scss';

.ff-dax-pro {
    font-family: $dax-pro !important;
}

.ff-noto-sans {
    font-family: $noto-sans !important;
}

.ff-dax-compact-pro {
    font-family: $dax-compact-pro !important;
}

.ff-dax-pro-condensed {
    font-family: $dax-pro-condensed !important;
}

.ff-dax-pro-wide {
    font-family: $dax-pro-wide !important;
}