@import '../variables/index.scss';

.pq-input-group {
    input {
        width: 100% !important;
        border-radius: 3px 0px 0px 3px !important;
        font-size: 14px !important;
        font-family: $noto-sans !important;
        height: 40px !important;
        border-top: 1px solid $medium-gray !important;
        border-left: 1px solid $medium-gray !important;
        border-bottom: 1px solid $medium-gray !important;
        border-right: none;
        outline: none !important;
        box-shadow: none !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        font-weight: 400 !important;

        &.error {
            border-top: 1px solid $orange !important;
            border-left: 1px solid $orange !important;
            border-bottom: 1px solid $orange !important;
        }
    }

    button {
        font-family: $noto-sans !important;
        background-color: $orange !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        height: 40px !important;
        border-radius: 0px 3px 3px 0px !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        color: $white !important;
        font-size: 16px !important;
        font-weight: 400 !important;

        &:hover {
            opacity: 0.8;
        }
    }
}