@import '../variables/index.scss';

.border-white {
    border-color: $white  !important;
}

.border-white-secondary {
    border-color: $white-secondary  !important;
}

.border-dark-blue {
    border-color: $dark-blue  !important;
}

.border-blue {
    border-color: $blue  !important;
}

.border-orange {
    border-color: $orange  !important;
}

.border-light-gray {
    border-color: $light-gray  !important;
}

.border-medium-gray {
    border-color: $medium-gray  !important;
}

.border-dark-gray {
    border-color: $dark-gray  !important;
}

.border-extra-dark-gray {
    border-color: $extra-dark-gray  !important;
}

.border-light-transparent {
    border-color: $light-transparent  !important;
}

.border-medium-transparent {
    border-color: $medium-transparent  !important;
}

.border-dark-transparent {
    border-color: $dark-transparent  !important;
}

.border-extra-dark-transparent {
    border-color: $extra-dark-transparent  !important;
}

.border-kb-dark-blue {
    border-color: $kb-dark-blue  !important;
}

.border-kb-medium-blue {
    border-color: $kb-medium-blue  !important;
}

.border-kb-blue {
    border-color: $kb-blue  !important;
}

.border-kb-gold {
    border-color: $kb-gold  !important;
}

.border-kb-light-gray {
    border-color: $kb-light-gray  !important;
}

.border-kb-gray {
    border-color: $kb-gray  !important;
}

.border-kb-dark-gray {
    border-color: $kb-dark-gray  !important;
}

.border-toast-green {
    border-color: $toast-green  !important;
}

.border-cold-purple {
    border-color: $cold-purple  !important;
}