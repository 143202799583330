// Margin
@for $i from 0 to 200 {
    .m-#{$i}px {
        margin: #{$i}px !important;
    }
}

// Margin Top
@for $i from 0 to 200 {
    .mt-#{$i}px {
        margin-top: #{$i}px !important;
    }
}

// Margin Bottom
@for $i from 0 to 200 {
    .mb-#{$i}px {
        margin-bottom: #{$i}px !important;
    }
}

// Margin Left
@for $i from 0 to 200 {
    .ms-#{$i}px {
        margin-left: #{$i}px !important;
    }
}

// Margin Right
@for $i from 0 to 200 {
    .me-#{$i}px {
        margin-right: #{$i}px !important;
    }
}

// Margin Horizontal
@for $i from 0 to 200 {
    .mx-#{$i}px {
        margin-left: #{$i}px !important;
        margin-right: #{$i}px !important;
    }
}

// Margin Vertical
@for $i from 0 to 200 {
    .my-#{$i}px {
        margin-top: #{$i}px !important;
        margin-bottom: #{$i}px !important;
    }
}