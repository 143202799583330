@import '../variables/index.scss';

.pq-spinner {
    width: 25px;
    height: 25px;
    margin: 0px auto;

    .pq-spinner-stroke {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border-color: $white;
        border-width: 2px;
        border-top-color: $dark-blue;
        border-style: solid;

        animation-name: spinner;
        animation-duration: 0.5s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
}

@keyframes spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}