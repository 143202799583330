// Padding
@for $i from 0 to 1000 {
    .p-#{$i}px {
        padding: #{$i}px !important;
    }
}

// Padding Top
@for $i from 0 to 1000 {
    .pt-#{$i}px {
        padding-top: #{$i}px !important;
    }
}

// Padding Bottom
@for $i from 0 to 1000 {
    .pb-#{$i}px {
        padding-bottom: #{$i}px !important;
    }
}

// Padding Left
@for $i from 0 to 1000 {
    .ps-#{$i}px {
        padding-left: #{$i}px !important;
    }
}

// Padding Right
@for $i from 0 to 1000 {
    .pe-#{$i}px {
        padding-right: #{$i}px !important;
    }
}

// Padding Horizontal
@for $i from 0 to 1000 {
    .px-#{$i}px {
        padding-left: #{$i}px !important;
        padding-right: #{$i}px !important;
    }
}

// Padding Vertical
@for $i from 0 to 1000 {
    .py-#{$i}px {
        padding-top: #{$i}px !important;
        padding-bottom: #{$i}px !important;
    }
}