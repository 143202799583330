@import '../variables/index.scss';

.text-white {
    color: $white  !important;
}

.text-white-secondary {
    color: $white-secondary  !important;
}

.text-dark-blue {
    color: $dark-blue  !important;
}

.text-kb-dark-blue {
    color: $kb-dark-blue  !important;
}

.text-blue {
    color: $blue  !important;
}

.text-orange {
    color: $orange  !important;
}

.text-light-gray {
    color: $light-gray  !important;
}

.text-medium-gray {
    color: $medium-gray  !important;
}

.text-dark-gray {
    color: $dark-gray  !important;
}

.text-extra-dark-gray {
    color: $extra-dark-gray  !important;
}

.text-light-transparent {
    color: $light-transparent  !important;
}

.text-medium-transparent {
    color: $medium-transparent  !important;
}

.text-dark-transparent {
    color: $dark-transparent  !important;
}

.text-extra-dark-transparent {
    color: $extra-dark-transparent  !important;
}

.text-kb-dark-blue {
    color: $kb-dark-blue  !important;
}

.text-kb-medium-blue {
    color: $kb-medium-blue  !important;
}

.text-kb-blue {
    color: $kb-blue  !important;
}

.text-kb-gold {
    color: $kb-gold  !important;
}

.text-kb-light-gray {
    color: $kb-light-gray  !important;
}

.text-kb-gray {
    color: $kb-gray  !important;
}

.text-kb-dark-gray {
    color: $kb-dark-gray  !important;
}

.text-toast-green {
    color: $toast-green  !important;
}

.text-cold-purple {
    color: $cold-purple  !important;
}