@import '../variables/index.scss';

.pq-stoke-text {
    font-family: $dax-pro !important;
    font-weight: 700 !important;
    font-size: 28px !important;
    -webkit-text-fill-color: $white !important;
    -webkit-text-stroke-width: 1px !important;
    -webkit-text-stroke-color: $orange !important;
    font-stretch: expanded;
}