@import '../variables/index.scss';

@for $i from 0 to 9 {
    .fw-#{$i * 100} {
        font-weight: #{$i * 100} !important;
    }
}

@each $key,
$value in $font-weights {
    .fw-#{$key} {
        font-weight: $value !important;
    }
}