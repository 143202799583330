@import '../variables/index.scss';

.bg-white {
    background-color: $white  !important;
}

.bg-white-secondary {
    background-color: $white-secondary  !important;
}

.bg-dark-blue {
    background-color: $dark-blue  !important;
}

.bg-blue {
    background-color: $blue  !important;
}

.bg-orange {
    background-color: $orange  !important;
}

.bg-light-gray {
    background-color: $light-gray  !important;
}

.bg-medium-gray {
    background-color: $medium-gray  !important;
}

.bg-dark-gray {
    background-color: $dark-gray  !important;
}

.bg-extra-dark-gray {
    background-color: $extra-dark-gray  !important;
}

.bg-light-transparent {
    background-color: $light-transparent  !important;
}

.bg-medium-transparent {
    background-color: $medium-transparent  !important;
}

.bg-dark-transparent {
    background-color: $dark-transparent  !important;
}

.bg-extra-dark-transparent {
    background-color: $extra-dark-transparent  !important;
}

.bg-kb-dark-blue {
    background-color: $kb-dark-blue  !important;
}

.bg-kb-medium-blue {
    background-color: $kb-medium-blue  !important;
}

.bg-kb-blue {
    background-color: $kb-blue  !important;
}

.bg-kb-gold {
    background-color: $kb-gold  !important;
}

.bg-kb-light-gray {
    background-color: $kb-light-gray  !important;
}

.bg-kb-gray {
    background-color: $kb-gray  !important;
}

.bg-kb-dark-gray {
    background-color: $kb-dark-gray  !important;
}

.bg-toast-green {
    background-color: $toast-green  !important;
}

.bg-cold-purple {
    background-color: $cold-purple  !important;
}