@import '../variables/index.scss';

.pq-btn-orange {
    font-family: $dax-pro !important;
    background-color: $orange !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    height: 40px !important;
    border-radius: 8px !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    color: $white !important;
    font-size: 16px !important;
    text-transform: uppercase !important;
    font-weight: 400 !important;

    &:hover {
        opacity: 0.8;
    }
}