@import 'bootstrap';
@import 'variables/colors';

@import 'components/index.scss';
@import 'gutters/index.scss';
@import 'typography/index.scss';
@import 'theme/index.scss';
@import 'misc.scss';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $white-secondary !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}