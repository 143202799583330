@import '../variables/index.scss';

.pq-input {
    width: 100% !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    font-family: $noto-sans !important;
    height: 40px !important;
    border: 1px solid $medium-gray !important;
    outline: none !important;
    box-shadow: none !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-weight: 400 !important;
}

::placeholder {
    color: $dark-gray !important;
    font-size: 14px !important;
}