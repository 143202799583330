.pq-knackbox-project {
    .newsletter {
        background-image: url('../../assets/svg/newsletter.svg');
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
    }

    @media only screen and (max-width: 767px) {
        .newsletter {
            background-image: url('../../assets/svg/newsletter-sm.svg');
        }
    }

    @media only screen and (min-width: 768px) {
        .newsletter {
            background-image: url('../../assets/svg/newsletter.svg');
        }
    }
}