.pq-project-container {
    .pq-transparent-container {
        animation-name: fadeIn;
        animation-duration: 0.2s;
        animation-timing-function: ease-in;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }

    .pq-slide-up-container {
        animation-name: slideUp;
        animation-duration: 0.35s;
        animation-timing-function: ease-in;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        top: 100%;
        left: 0;
        opacity: 0;
        transform: scale(0);
    }

    to {
        top: 0;
        left: 0;
        opacity: 1;
        transform: scale(1);
    }
}