@import '../variables/index.scss';

.pq-card {
    background-color: $light-gray  !important;
    border-radius: 0px 0px 12px 12px !important;
    border-top: 4px solid $orange  !important;
    transition: max-height 0.2s linear;

    &.pq-collapse {
        max-height: 90px;
        transition: max-height 0.2s linear;
    }

    @for $height from 0 to 1000 {
        &.expand-#{$height}px {
            max-height: #{$height}px;
            transition: max-height 0.2s linear;
        }
    }
}

@media only screen and (max-width: 767px) {
    .pq-card {
        padding: 24px 18px !important;
    }
}

@media only screen and (min-width: 768px) {
    .pq-card {
        padding: 24px 18px !important;
    }
}