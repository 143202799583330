@import url("https://use.typekit.net/fvt8lue.css");
@import '../variables/index.scss';

// Noto Sans

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: auto;
    font-stretch: normal;
}

@font-face {
    font-family: $noto-sans;
    src: local($noto-sans), url('../../assets/fonts/NotoSans-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: auto;
    font-stretch: normal;
}