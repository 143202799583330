@import '../variables/index.scss';

.pq-textarea {
    width: 100% !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    font-family: $noto-sans !important;
    height: 85px !important;
    border: 1px solid $medium-gray !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 10px 15px !important;
    resize: none !important;
}