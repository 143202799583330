// Width
@for $i from 0 to 1000 {
    .w-#{$i}px {
        width: #{$i}px !important;
    }
}

@for $i from 0 to 1000 {
    .w-#{$i} {
        width: #{$i}#{'%'} !important;
    }
}

@for $i from 0 to 1000 {
    .max-w-#{$i}px {
        max-width: #{$i}px !important;
    }
}

@for $i from 0 to 1000 {
    .max-w-#{$i} {
        max-width: #{$i}#{'%'} !important;
    }
}

@for $i from 0 to 1000 {
    .min-w-#{$i}px {
        min-width: #{$i}px !important;
    }
}

@for $i from 0 to 1000 {
    .min-w-#{$i} {
        min-width: #{$i}#{'%'} !important;
    }
}