@import '../variables/colors';

.pq-subscribe-sub-heading {
    position: relative;
    overflow-x: hidden;

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 1%;
        left: -100%;
        width: 100%;
        height: 2px;
        background-color: $orange;
        transition: left 0.01s linear;
    }

    &:hover {
        &::after {
            left: 0;
            transition: left 0.2s linear;
        }
    }
}