// Height
@for $i from 0 to 1000 {
    .h-#{$i}px {
        height: #{$i}px !important;
    }
}

@for $i from 0 to 1000 {
    .h-#{$i} {
        height: #{$i}#{'%'} !important;
    }
}

@for $i from 0 to 1000 {
    .max-h-#{$i}px {
        max-height: #{$i}px !important;
    }
}

@for $i from 0 to 1000 {
    .max-h-#{$i} {
        max-height: #{$i}#{'%'} !important;
    }
}

@for $i from 0 to 1000 {
    .min-h-#{$i}px {
        min-height: #{$i}px !important;
    }
}

@for $i from 0 to 1000 {
    .min-h-#{$i} {
        min-height: #{$i}#{'%'} !important;
    }
}